* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen zorana grubovic container */
.ss-zorana-grubovic-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen zorana grubovic container container */
.ss-zorana-grubovic-container-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 451px) {
  /* small screen zorana grubovic container */
  .ss-zorana-grubovic-container {
    display: none;
  }
}










/* large screen zorana grubovic container */
.ls-zorana-grubovic-container {

}

@media screen and (max-width: 450px) {
  /* large screen zorana grubovic container */
  .ls-zorana-grubovic-container {
    display: none;
  } 
}

