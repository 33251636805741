* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

::-webkit-scrollbar {
  width: 0.521vw;
}
::-webkit-scrollbar-thumb {
  width: 0.521vw;
  background-color: rgba(179,82,38,1);
}

/* small screen home container */
 .ss-home-container {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 451px) {
  /* small screen home container */
  .ss-home-container {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  /* large screen home container */
  .ls-home-container {
    display: none;
  }
}
