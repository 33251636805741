* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen mona malicevic container */
.ss-mona-malicevic-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen mona malicevic container container */
.ss-mona-malicevic-container-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 451px) {
  /* small screen mona malicevic container */
  .ss-mona-malicevic-container {
    display: none;
  }
}










/* large screen mona malicevic container */
.ls-mona-malicevic-container {

}

@media screen and (max-width: 450px) {
  /* large screen mona malicevic container */
  .ls-mona-malicevic-container {
    display: none;
  } 
}

