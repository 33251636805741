/* large screen home question container */
.ls-home-question-container {
  width: 100%;
  background: linear-gradient(180deg, rgba(224,224,224,1) 0%, rgba(235,235,235,1) 5%, rgba(252,252,252,1) 10%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* large screen home question container container */
.ls-home-question-container-container {
  width: 83.333vw;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6.667vw;
  margin-bottom: 6.667vw;
}

/* large screen home question image container */
.ls-home-question-image-container {
  width: fit-content;
  height: 30vw;
  position: relative;
  margin-left: 2.5vw;
}

.ls-home-question-image-container img {
 height: 100%;
}

/* large screen home question decoration */
.ls-home-question-decoration {
  width: 16.667vw;
  height: 16.667vw;
  border-radius: 16.667vw;
  background: linear-gradient(140deg, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%);
  position: absolute;
  top: 7.5vw;
}

/* large screen home question text container */
.ls-home-question-text-container {
  width: 38.333vw;
  height: fit-content;
  z-index: +1;
}

@media screen and (max-width: 450px) {
  /* large screen home question container */
  .ls-home-question-container {
    display: none;
  }
}