* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen doctors navigation dropdown list item container */
.ss-doctors-navigation-dropdown-list-item {
  width: 100%;
  height: 9.852vh;
  border-top: 3px solid black;
  border-image: linear-gradient(90deg, rgba(148,59,39,1), rgba(240,167,128,1)) 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-doctors-navigation-dropdown-list-item h1 {
  font-family: Quicksand-VariableFont;
  font-size: 3.733vw;
  color: black;
}