@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

@media screen and (min-width: 451px) {

  /* small devices footer container */
  .ss-footer-container {
    display: none;
  }
}

/* small devices footer container */
.ss-footer-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #EFEFEF;
}

.ss-footer-container-container {
  width: 100%;
  height: 84vh;
  margin-top: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* small devices footer container una logo */
.ss-footer-container-una-logo {
  width: 100%;
  height: 11.392vh;
  text-align: center;
}

.ss-footer-container-una-logo img {
  height: 100%;
}

/* small devices footer container candela logo */
.ss-footer-container-candela-logo {
  width: 100%;
  height: 5.06vh;
  text-align: center;
}

.ss-footer-container-candela-logo img {
  height: 100%;
}

/* small devices footer container paragraph */
.ss-footer-container-paragraph {
  width: 100%;
  font-family: Quicksand-VariableFont;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3.889vw;
}

.ss-footer-container-paragraph p {
  display: inline-block;
  text-align: left;
}

.ss-footer-container-paragraph p a {
  text-decoration: none;
  color: black;
}

/* small devices footer container icons */
.ss-footer-container-icons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 6.667%;
}

/* small devices footer container copyright */
.ss-footer-container-copyright {
  width: 100%;
  font-family: Quicksand-VariableFont;
  font-size: 2.778vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
