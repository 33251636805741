/* small screen contact container */
.ss-contact-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen contact logo & info & map container */
.ss-contact-logo-info-map-container {
  width: 84vw;
  height: 92.118vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* small screen contact logo container */
.ss-contact-logo-container {
  width: 84vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6.579vh;
}

.ss-contact-logo-container img {
  width: 84vw;
}

/* small screen contact info container */
.ss-contact-info-container {
}

.ss-contact-info-container p {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 4.444vw;
}

.ss-contact-info-container p a {
  text-decoration: none;
  color: black;
}

/* small screen contact map container */
.ss-contact-map-container {
  max-width: 100%;
  max-height: 36.1905vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 6.579vh;
}

.ss-contact-map-container iframe{
  border: none;
  width: 100%;
  height: 36.1905vh;
}

@media screen and (min-width: 451px) {
  /* small screen contact container */
  .ss-contact-container {
    display: none;
  }
}










/* large screen contact container */
.ls-contact-container {

}

/* large screen contact title container */
.ls-contact-title-container {
  width: 100%;
  height: 8.333vw;
  background: linear-gradient(90deg, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-contact-title-container p {
  font-family: Quicksand-VariableFont;
  font-size: 2.500vw;  
  color: white;
}

/* large screen contact logo & info & map container */
.ls-contact-logo-info-map-container {
  width: 82.75vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 8.46vw;
  margin-top: 4.117vw;
  margin-bottom: 4.117vw;
}

/* large screen contact logo & info container */
.ls-contact-logo-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.333vw;
}

.ls-contact-logo-info-container a {
  text-decoration: none;
  color: black;
}

.ls-contact-logo-info-container img {
  width: 13.333vw;
}

.ls-contact-logo-info-container p {
  font-family: Quicksand-VariableFont;
  font-weight: 500;
  font-size: 1.667vw;
}

/* large screen contact map container */
.ls-contact-map-container {
  width: 50vw;
}

.ls-contact-map-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (max-width: 450px) {
  /* large screen contact container */
  .ls-contact-container {
    display: none;
  }  
}