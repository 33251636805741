* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* large screen blog field card container */
.ls-blog-field-card-container {

}

/* large screen blog field card button container */
.ls-blog-field-card-button-container {
  width: 25vw;
  height: 3.333vw;
  background-color: #FFEEDE;
  border-radius: 1.667vw;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

/* large screen blog field card button inactive container container */
.ls-blog-field-card-button-inactive-container-container {
  width: 21.667vw;
  height: 3.333vw;
  background: white;
  border-radius: 1.667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.052vw solid rgba(163,71,39,1);
}

.ls-blog-field-card-button-inactive-container-container p {
  font-family: Quicksand-VariableFont;
  font-weight: 600;
  color: rgba(163,71,39,1);
  font-size: 0.938vw;
  padding-left: 1.667vw;
  padding-right: 1.667vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* large screen blog field card button active container container */
.ls-blog-field-card-button-active-container-container {
  width: 21.667vw;
  height: 3.333vw;
  background: linear-gradient(90deg, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%);
  border-radius: 1.667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-blog-field-card-button-active-container-container p {
  font-family: Quicksand-VariableFont;
  color: white;
  font-size: 0.938vw;
  padding-left: 1.667vw;
  padding-right: 1.667vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* large screen blog field card button arrow container */
.ls-blog-field-card-button-arrow-container {
  width: 3.333vw;
  height: 3.333vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-blog-field-card-button-arrow-container img {
  max-height: 50%;
  width: 50%;
}

/* large screen blog field card content container */
.ls-blog-field-card-content-container {
  width: 25vw;
  margin-top: 1.667vw;
  border-bottom: 0.26vw solid;
  border-image: linear-gradient(to right, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%) 1;
  display: flex;
  flex-direction: column;
  gap: 0.833vw;
}

.ls-blog-field-card-content-container p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* large screen blog field card content paragraph */
.ls-blog-field-card-content-paragraph {
  font-family: Quicksand-VariableFont;
  font-weight: 600;
  font-size: 0.833vw;
  color: black;
}

@media screen and (max-width: 450px) {
  /* large screen blog field card container */
  .ls-blog-field-card-container {
    display: none;
  } 
}