* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen doctors navigation dropdown icons */
.ss-doctors-navigation-dropdown-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-doctors-navigation-dropdown-icons img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 450px) {
  /* small screen doctors navigation dropdown icons */
  .ss-doctors-navigation-dropdown-icons {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 430px) {
  /* small screen doctors navigation dropdown icons */
  .ss-doctors-navigation-dropdown-icons {
    width: 28.7px;
    height: 28.7px;
  }
}

@media screen and (max-width: 414px) {
  /* small screen doctors navigation dropdown icons */
  .ss-doctors-navigation-dropdown-icons {
    width: 27.6px;
    height: 27.6px;
  }
}

@media screen and (max-width: 393px) {
  /* small screen doctors navigation dropdown icons */
  .ss-doctors-navigation-dropdown-icons {
    width: 26.2px;
    height: 26.2px;
  }
}

@media screen and (max-width: 375px) {
  /* small screen doctors navigation dropdown icons */
  .ss-doctors-navigation-dropdown-icons {
    width: 25px;
    height: 25px;
  }
}