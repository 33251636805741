/* small screen services field container */
.ss-services-field-container {
 width: 71.2vw;
 margin-top: 8.889vw;
}

/* small screen services field title container */
.ss-services-field-title-container {
  width: 100%;
  height: 13.333vw;
  border-top: 0.833vw solid;
  border-bottom: 0.833vw solid;
  border-image: linear-gradient(90deg, rgba(148,59,39,1), rgba(240,167,128,1)) 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* small screen services field title container */
.ss-services-field-title-container p {
  font-family: Quicksand-VariableFont;
  font-size: 5vw;
  letter-spacing: 1px;
  color: #B35226;
  font-weight: 700;
}

/* small screen services field text container */
.ss-services-field-text-container {
  margin-top: 4.444vw;
}

/* small screen services field text container */
.ss-services-field-text-container p {
  font-family: Quicksand-VariableFont;
  color: black;
  font-weight: 500;
  font-size: 4.444vw;
}

/* small screen services field image */
.ss-services-field-image {
  margin-top: 4.444vw;
  width: 44.444vw;
}

/* small screen field text horizontal rule */
.ss-services-field-text-horizontal-rule {
  border-bottom: 0.156vw solid;
  border-image: linear-gradient(90deg, rgba(148,59,39,1), rgba(240,167,128,1)) 1;
  margin-top: 4.444vw;
  margin-bottom: 4.444vw;
}

@media screen and (min-width: 451px) {
  /* small screen services field container */
  .ss-services-field-container {
    display: none;
  }
}