/* large screen home questions question container */
.ls-home-questions-question-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* large screen home question title container */
.ls-home-question-title-container {
  width: 100%;
  height: 7.5vw;
  cursor: pointer;
  border-bottom: 0.260vw solid;
  border-image: linear-gradient(to right, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%) 1;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 3.333vw;
}

.ls-home-question-title-container p {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.667vw;
  font-weight: 500;
}

/* large screen home question arrow container */
.ls-home-question-arrow-container {
  width: 2.5vw;
  height: 2.5vw;
}

.ls-home-question-arrow-container img {
  width: 100%;
}

/* large screen home question answer container */
.ls-home-question-answer-container {
  width: 100%;
  border-image: linear-gradient(to right, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%) 1;
}

.ls-home-question-answer-container p {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.667vw;
  padding-left: 3.333vw;
  padding-right: 5.833vw;
  padding-bottom: 2.5vw;
}

@media screen and(max-width: 450px) {
  /* large screen home questions question container */
  .ls-home-questions-question-container {
    display: none;
  } 
}