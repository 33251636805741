* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen employe back icon container */
.ss-employe-back-icon-container {
  width: 100vw;
  height: 4.187vh;
  margin-top: 0.985vh;
}

.ss-employe-back-icon-container img {
  height: 100%;
  padding-left: 2.133vw;
}