/* large screen home header container */
.ls-home-header-container {
  width: 100%;
  height: 8.333vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* large screen home header container container */
.ls-home-header-container-container {
  width: 86.667vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* large screen home header logo container */
.ls-home-header-logo-container {
  width: 23.333vw;
  display: flex;
}

.ls-home-header-logo-container img {
  width: 100%;
  cursor: pointer;
}

/* large screen home header navigation & button */
.ls-home-header-navigation-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3.333vw;
}

/* large screen home header navigation container */
.ls-home-header-navigation-container {
  display: inline-flex;
  gap: 1.667vw;
}

.ls-home-header-navigation-container li {
  list-style: none;
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 0.938vw;
}

/* large screen home header button container */
.ls-home-header-button-container {
  display: flex;
  flex-direction: row;
  gap: 1.667vw;
}

/* large screen home header button */
.ls-home-header-button {
  width: 13.333vw;
  height: 3.333vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* large screen home header contact button */
.ls-home-header-contact-button {
  background: linear-gradient(90deg, rgba(250,200,100,1) 0%, rgba(250,200,100,1) 100%);
}

/* large screen home header blog button */
.ls-home-header-blog-button {
  background: linear-gradient(90deg, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%);
}

.ls-home-header-button  p {
  font-family: Quicksand-VariableFont;
  font-size: 0.938vw;
  color: white;
  letter-spacing: 0.260vw;
}