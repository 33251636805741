@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../assets//fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* large screen blog container */
.ls-blog-container {
  width: 100%;
}

/* large screen blog title container */
.ls-blog-title-container {
  width: 100%;
  height: 8.333vw;
  background: linear-gradient(90deg, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-blog-title-container p {
  font-family: Quicksand-VariableFont;
  font-size: 2.500vw;  
  color: white;
}

/* large screen blog container container */
.ls-blog-container-container {
  width: 100%;
  margin-top: 6.667vw;
  margin-bottom: 6.667vw;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

/* large screen blog left container */
.ls-blog-left-container {
  width: 65vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 8.333vw;
}

/* large screen blog left container path */
.ls-blog-left-container-path {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.146vw;
  margin-left: 8.333vw;
}

/* large screen blog left filter container */
.ls-blog-left-filter-container {
  width: 65vw;
  border-top: 0.26vw solid #f2f2f2;
  border-bottom: 0.26vw solid #f2f2f2;
  display: flex;
  flex-direction: column;
  margin-top: 3.333vw;
}

/* large screen blog left filter title */
.ls-blog-left-filter-title {
  font-family: Quicksand-VariableFont;
  font-weight: 600;
  color: rgba(163,71,39,1);
  font-size: 1.146vw;
  margin-top: 2.5vw;
  margin-left: 8.333vw;
}

/* large screen blog left filter options container */
.ls-blog-left-filter-options-container {
  width: 51.667vw;
  margin-top: 2.5vw;
  margin-left: 8.333vw;
  margin-bottom: 2.5vw;
  display: flex;
  flex-direction: column;
  gap: 0.833vw;
}

.ls-blog-left-filter-options-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ls-blog-left-filter-option-button {
  width: 16.667vw;
  height: 1.667vw;
  background-color: #f2f2f2;
  font-family: Quicksand-VariableFont;
  font-size: 0.729vw;
  cursor: pointer;
  border-radius: 1.667vw;
  padding-left: 1.667vw;
  padding-right: 1.667vw;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ls-blog-left-filter-option-button p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 3; /* increased line height so second row could not be seen */
}

.ls-blog-left-filter-option-active-button {
  background-image:   linear-gradient(90deg, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%);
  width: 16.667vw;
  height: 1.667vw;
  font-family: Quicksand-VariableFont;
  font-size: 0.729vw;
  cursor: pointer;
  border-radius: 1.667vw;
  padding-left: 1.667vw;
  padding-right: 1.667vw;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-blog-left-filter-option-active-button p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 3; /* increased line height so second row could not be seen */
}

/* large screen blog left post list container */
.ls-blog-left-post-list-container {
  margin-top: 3.333vw;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

@media screen and (max-width: 450px) {
  /* large screen blog container */
  .ls-blog-container {
    display: none;
  }
}










/* small screen blog container */
.ss-blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen blog title 1 container */
.ss-blog-title-1-container {
  width: 100%;
  height: 10vh;
  background: linear-gradient(90deg, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* small screen blog title 1 container container */
.ss-blog-title-1-container-container {
  width: 84vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ss-blog-title-1-container-container p {
  font-family: Quicksand-VariableFont;
  font-size: 6.400vw;
  color: white;
  letter-spacing: 0.5px;
}

/* small screen blog filter icon container */
.ss-blog-filter-icon-container {
  width: 6.667vw;
  height: 6.667vw;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-blog-filter-icon-container img {
  width: 100%;
  filter: invert(31%) sepia(92%) saturate(458%) hue-rotate(330deg) brightness(90%) contrast(95%);
}

/* small screen filter list container */
.ss-filter-list-container {
  width: 90.9095vw;
  background: white;
  position: absolute;
  left: 0;
  top: 17.882vh;
  margin-top: 8.889vw;
  border-top: 1px solid rgba(163,71,39,1);
  border-right: 1px solid rgba(163,71,39,1);
  border-bottom: 1px solid rgba(163,71,39,1);
  padding-left: 3.333vw;
  padding-right: 3.333vw;
  padding-top: 3.333vw;
  padding-bottom: 3.333vw;
  margin-right: 9.0905vw;
  display: flex;
  flex-direction: column;
  gap: 3.333vw;
}

.ss-filter-list-container p {
  font-family: Quicksand-VariableFont;
  color: rgba(163,71,39,1);
  font-weight: 600;
  font-size: 4.444vw;
}

.ss-filter-list-container-container {
  margin-left: 3.333vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3.333vw;
  overflow: hidden;
}

.ss-filter-list-container-container p {
  font-family: Quicksand-VariableFont;
  color: black;
  font-weight: 400;
  font-size: 3.889vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* small screen filter list checkbox */
.ss-filter-list-checkbox {
  min-width: 4.167vw;
  height: 4.167vw;
  border: 1px solid rgba(163,71,39,1);
}

.ss-filter-list-checkbox-active {
  min-width: 4.167vw;
  height: 4.167vw;
  background-color: rgba(163,71,39,1);
}

/* small screen blog post list constainer */
.ss-blog-post-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
  margin-top: 8.889vw;
}

@media screen and (min-width: 450px) {
  /* small screen blog container */
  .ss-blog-container {
    display: none;
  }
}