/* large screen employe profile container */
.ls-employe-profile-container {
  width: 100%;
}

/* large screen employe profile title container */
.ls-employe-profile-title-container {
  width: 100%;
  height: 8.333vw;
  background: linear-gradient(90deg, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-employe-profile-title-container p {
  font-family: Quicksand-VariableFont;
  font-size: 2.500vw;  
  color: white;
}

/* large screen employe profile container container */
.ls-employe-profile-container-container {
  width: 100%;
  margin-top: 6.667vw;
  display: flex;
  flex-direction: row;
}

/* large screen employe profile left container */
.ls-employe-profile-left-container {
  width: 60vw;
  padding-bottom: 6.667vw;
}

/* large screen employe profile left image & info container */
.ls-employe-profile-left-image-info-container {
  width: 43.333vw;
  height: 38.333vw;
  margin-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* large screen employe profile left image container */
.ls-employe-profile-left-image-container {
  width: 36.666vw;
  height: 22.5vw;
  border-bottom: 0.52vw solid;
  border-image: linear-gradient(to right, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%) 1;
  margin-top: 2.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ls-employe-profile-left-image-container img {
  height: 100%;
}

/* large screen employe profile left info container */
.ls-employe-profile-left-info-container {
  width: 36.666vw;
  height: 13.333vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* large screen employe profile left info name */
.ls-employe-profile-left-info-name {
  font-family: Quicksand-VariableFont;
  color: #a24c3b;
  font-size: 2.188vw;
  font-weight: 600;
  margin-top: 1.667vw;
}

/* large screen employe profile left info name */
.ls-employe-profile-left-info-description {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.667vw;
  padding-left: 1.667vw;
  padding-right: 1.667vw;
  margin-top: 1.667vw;
}

/* large screen employe profile left decoration 1 */
.ls-employe-profile-left-decoration-1 {
  width: 11.667vw;
  height: 11.667vw;
  position: absolute;
  top: 0;
  right: 0;
}

/* large screen employe profile left decoration 2 */
.ls-employe-profile-left-decoration-2 {
  width: 11.667vw;
  height: 11.667vw;
  transform: rotate(180deg);
  position: absolute;
  left: 0;
  bottom: 0;
}

/* large screen employe profile left services container */
.ls-employe-profile-left-services-container {
  width: 43.333vw;
  margin-left: 10vw;
  margin-top: 6.667vw;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

/* large screen employe profile left services list item container */
.ls-employe-profile-left-services-list-item-container {
  width: 100%;
  min-height: 3.333vw;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ls-employe-profile-left-services-list-item-container p {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.042vw;
  padding-top: 0.833vw;
  padding-bottom: 0.833vw;
  padding-left: 1.667vw;
  padding-right: 1.667vw;
}

/* large screen employe profile left reservation button */
.ls-employe-profile-left-reservation-button {
  width: 26.667vw;
  height: 5vw;
  border-radius: 26.667vw;
  background: linear-gradient(90deg, rgba(250,200,100,1) 0%, rgba(250,200,100,1) 100%);
  margin-top: 5vw;
  margin-left: 18.333vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ls-employe-profile-left-reservation-button p {
  font-family: Quicksand-VariableFont;
  color: white;
  font-size: 1.667vw;
  font-weight: 500;
}

/* large screen employe profile left gallery container */
.ls-employe-profile-left-gallery-container {
  width: 43.333vw;
  margin-left: 10vw;
}

/* large screen employe profile left horizontal rule */
.ls-employe-profile-left-horizontal-rule {
  width: 43.333vw;
  margin-top: 5vw;
  border-bottom: 0.26vw solid;
  border-image: linear-gradient(to right, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 100%) 1;
}

/* large screen employe profile left gallery title */
.ls-employe-profile-left-title {
  font-family: Quicksand-VariableFont;
  color: #ae5029;
  font-size: 1.979vw;
  font-weight: 800;
  margin-top: 3.333vw;
}

/* large screen employe profile left images container */
.ls-employe-profile-left-images-container {
  margin-top: 3.333vw;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

/* large screen employe profile left images row container */
.ls-employe-profile-left-images-row-container {
  width: 43.333vw;
  height: 13.333vw;
  display: flex;
  flex-direction: row;
  gap: 1.667vw;
}

/* large screen employe profile left image */
.ls-employe-profile-left-images-row-container img {
  width: 13.333vw;
  height: 13.333vw;
  cursor: pointer;
}

/* large screen employe profile left video container */
.ls-employe-profile-left-video-container {
  width: 43.333vw;
  margin-top: 3.333vw;
}

/* large screen employe profile left biography container */
.ls-employe-profile-left-biography-container {
  width: 43.333vw;
  margin-left: 10vw;
  margin-top: 5vw;
}

/* large screen employe profile left biography text container */
.ls-employe-profile-left-biography-text-container {
  width: 43.333vw;
  margin-top: 1.667vw;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

.ls-employe-profile-left-biography-text {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.146vw;
}

.ls-employe-profile-left-biography-text-container ul {
  margin-left: 1.667vw;
}

/* large screen employe profile right container */
.ls-employe-profile-right-container {
  width: 40vw;
  border-left: 0.26vw solid;
  border-image: linear-gradient(to bottom, rgba(163,71,39,1) 0%, rgba(242,168,127,1) 50%, rgba(163,71,39,1) 100%) 1;
}

/* large screen employe profile right container container */
.ls-employe-profile-right-container-container {
  width: 25vw;
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  gap: 3.333vw;
}

@media screen and (max-width: 450px) {
  /* large screen employe profile container */
  .ls-employe-profile-container {
    display: none;
  } 
}