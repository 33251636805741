/* small screen icons footer container */
.ss-icons-footer-container {
  border-radius: 50%;

  .ss-icons-footer-container img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 450px) {

  /* small screen icons footer container */
  .ss-icons-footer-container {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 436px) {

  /* small screen icons footer container */
  .ss-icons-footer-container {
    width: 38.7px;
    height: 38.7px;
  }
}

@media screen and (max-width: 414px) {

  /* small screen icons footer container */
  .ss-icons-footer-container {
    width: 36.8px;
    height: 36.8px;
  }
}

@media screen and (max-width: 393px) {

  /* small screen icons footer container */
  .ss-icons-footer-container {
    width: 34.7px;
    height: 34.7px;
  }
}

@media screen and (max-width: 375px) {

  /* small screen icons footer container */
  .ss-icons-footer-container {
    width: 33.3px;
    height: 33.3px;
  }
}

@media screen and (max-width: 360px) {

  /* small screen icons footer container */
  .ss-icons-footer-container {
    width: 32px;
    height: 32px;
  }
}