/* large screen home part 4 employe container */
.ls-home-part-4-employe-container {
  width: 25vw;
  height: 25vw;
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* large screen home part 4 image container */
.ls-home-part-4-image-container {
  width: 21.667vw;
  height: 13.333vw;
  border-bottom: 0.260vw solid;
  border-image: linear-gradient(to right, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%) 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-home-part-4-image-container img {
  height: 100%;
}

/* large screen home part 4 text container */
.ls-home-part-4-text-container {
  width: 21.667vw;
  height: 8.333vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.667vw;
}

/* large screen home part 4 text name */
.ls-home-part-4-text-name {
  font-family: Quicksand-VariableFont;
  color: #A34727;
  font-size: 1.667vw;
  line-height: 1;
  font-weight: 600;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

/* large screen home part 4 text description */
.ls-home-part-4-text-description {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.042vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

/* large screen home part 4 decoration container */
.ls-home-part-4-decoration-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: +5;
}

.ls-home-part-4-decoration-container img {
  width: 6.667vw;
  height: 6.667vw;
  position: absolute;
}

.ls-home-part-4-decoration-1 {
  right: 0;
}

.ls-home-part-4-decoration-2 {
  bottom: 0;
  transform: rotate(180deg);
}

@media screen and (max-width: 450px) {
  /* large screen home part 4 employe container */
  .ls-home-part-4-employe-container {
    display: none;
  }
}