* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen employe make reservation container */
.ss-employe-make-reservation-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  gap: 3.2vw;
  justify-content: center;
}

.ss-employe-make-reservation-container a {
  text-decoration: none;
}

/* small screen employe make reservation phone container */
.ss-employe-make-reservation-phone-container {
  width: 17.067vw;
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.ss-employe-make-reservation-phone-container img {
  width: 100%;
  background-color: white;
}

/* small screen employe make reservation button container */
.ss-employe-make-reservation-button-container {
  width: 50.933vw;
  height: 100%;
  background: linear-gradient(90deg, #943B27 0%, #B35226 44.27%, #F0A780 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-employe-make-reservation-button-container p {
  font-family: Quicksand-VariableFont;
  color: white;
  font-size: 3.733vw;
  font-weight: 700;
  letter-spacing: 0.5px;
}