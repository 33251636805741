* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen part 5 container */
.ss-part5-container {
  width: 100%;

  /* small screen part 5 container questions */
  .ss-part5-container-questions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* small screen part 5 questions container */
    .ss-part5-questions-container {
      width: 82.222%;
      margin-top: 5.454vh;
      margin-bottom: 5.454vh;
    }

    /* small screen part 5 questions image */
    .ss-part5-questions-image {
      width: 100%;
      height: 31.515vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .ss-part5-questions-image img{
      height: 100%;
    }

    /* small screen part 5 questions question */
    .ss-part5-questions-question {
      width: 100%;
      border-bottom: 3px solid;
      border-image: linear-gradient(90deg, rgba(148,59,39,1), rgba(240,167,128,1)) 1;
    }
  }
}

@media screen and (min-width: 451px) {
  
  /* small screen part 5 container */
  .ss-part5-container {
    display: none;
  }

}