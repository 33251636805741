/* large screen home footer social media container */
.ls-home-footer-social-media-icon-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-home-footer-social-media-icon-container img {
  width: 100%;
  max-height: 100%;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  /* large screen home footer social media container */
  .ls-home-footer-social-media-container {
    display: none;
  }
}