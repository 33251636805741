* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen doctors doctor container */
.ss-doctors-doctor-container {
  width: 71.2vw;
  margin-top: 8.889vw;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen doctors doctor image container */
.ss-doctors-doctor-image-container {
  width: 64.267vw;
  height: 15.764vh;
  margin-top: 2.217vh;
  border-bottom: 3px solid;
  border-image: linear-gradient(90deg, rgba(148,59,39,1), rgba(240,167,128,1)) 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ss-doctors-doctor-image-container img {
  height: 100%;
}

/* small screen doctors doctor name & paragraph container */
.ss-doctors-doctor-name-paragraph-container {
  width: 64.267vw;
  margin-top: 2.217vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2.217vh;
}

/* small screen doctors doctor name */
.ss-doctors-doctor-name {
  font-family: Quicksand-VariableFont;
  color: #A15139;
  font-weight: 600;
  font-size: 5.333vw;
  line-height: 1;
}

/* small screen doctors doctor paragraph */
.ss-doctors-doctor-paragraph {
  font-family: Quicksand-VariableFont;
  color: black;
  font-weight: 400;
  font-size: 3.733vw;
  line-height: 1;
  margin-top: 1.231vh;
}

@media screen and (max-height: 640px) {
  /* small screen doctors doctor name */
  .ss-doctors-doctor-name {
    font-size: 4.444vw;
  }
}