/* large screen blog post container */
.ls-blog-post-container {
  width: 51.667vw;
  height: 23.333vw;
  background-color: #f2f2f2;
  margin-left: 8.333vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.667vw;
}

/* large screen blog post image container */
.ls-blog-post-image-container {
  width: 20vw;
  height: 20vw;
  margin-left: 1.667vw;
}

.ls-blog-post-image-container img {
  width: 100%;
}

/* large screen blog post text container */
.ls-blog-post-text-container {
  width: 26.666vw;
  height: 20vw;
  overflow: hidden;
}

.ls-blog-post-text-title {
  font-family: Quicksand-VariableFont;
  font-weight: 600;
  color: rgba(163,71,39,1);
  font-size: 2.292vw;
  line-height: 1.2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ls-blog-post-text-paragraph {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.250vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 450px) {
  /* large screen blog post container */
  .ls-blog-post-container {
    display: none;
  }
}