* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen part 2 container */
.ss-part2-container {
  width: 100vw;
  height: 100vh;
  background-color: #EFEFEF;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* small screen part 2 container container */
  .ss-part2-container-container {
    width: 100%;
    height: 86.842vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* small screen part 2 container paragraph */
    .ss-part2-container-paragraph {
      width: 100%;
      height: 6.579vh;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ss-part2-container-paragraph p {
      font-family: Quicksand-VariableFont;
      font-size: 5.556vw;
      line-height: 1;
      display: inline-block;
      text-align: left;
    }

    /* small screen part 2 container container container */
    .ss-part2-container-container-container {
      width: 100%;
      height: 74.342vh;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      position: relative;

      /* small screen part 2 container decoration */
      .ss-part2-container-decoration {
        background: linear-gradient(75deg, rgba(243,243,243,1) 0%, rgba(243,243,243,1) 50%, rgba(243,243,243,1) 100%);
        position: absolute;
        top: 0px;
        right: 17.778%;
        border-radius: 50%;
      }

      /* small screen part 2 container form */
      .ss-part2-container-form {
        width: 75.555%;
        height: 68.421vh;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 2.632vh;
        z-index: +1;

        /* small screen part 2 container form container */
        .ss-part2-container-form-container {
          width: 100%;
          height: 60.525vh;
          margin-top: 2.632vh;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }

        /* small screen part 2 container form logo */
        .ss-part2-container-form-logo {
          width: 100%;
          height: 2.263vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .ss-part2-container-form-logo img {
          height: 100%;
        }

        /* small screen part 2 container form input */
        .ss-part2-container-form-input {
          width: 80.222%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .ss-part2-container-form-input textarea{
          display: none; /* hide textarea in message input */
        }

        /* small screen part 2 container form input paragraph */
        .ss-part2-container-form-input p {
          font-family: Quicksand-VariableFont;
          font-size: 3.889vw;
          padding-bottom: 1.389vw;
        }
        
        /* small screen part 2 container form input input */
         .ss-part2-container-form-input input{
          width: 100%;
          background-color: transparent;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 3px solid #EFEFEF;
          font-family: Quicksand-VariableFont;
          font-size: 3.889vw;
        }
        
        .ss-part2-container-form-input input::-webkit-outer-spin-button,
        .ss-part2-container-form-input input::-webkit-inner-spin-button{
          -webkit-appearance: none; /* hide arrows in input */
        }
        
        /* small screen part 2 container form input placeholder*/
        .ss-part2-container-form-input input::placeholder{
          color: grey;
          font-family: Quicksand-VariableFont;
          font-size: 3.889vw;
        }

        .ss-part2-container-form-input input:focus{
          outline: none; /* hide borders on input when active */
        }

        /* small screen part 2 container form input message*/
        .ss-part2-container-form-input div {
          height: 13.158vh; 
          width: 100%;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 3px solid #EFEFEF;
          background-color: transparent;
          font-family: Quicksand-VariableFont;
          font-size: 3.889vw;
          overflow-y: hidden;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: end;
        }

        .ss-part2-container-form-input div:focus {
          outline: none; /* hide borders on input when active */
        }

        .ss-part2-container-form-input div[placeholder]:empty:before {
          content: attr(placeholder);
          font-family: Quicksand-VariableFont;
          font-size: 3.889vw;
          color: grey;
        }
      }

      /* small screen part2 container button */
      .ss-part2-container-button {
        width: 30%;
        height: 5.264vh;
        position: absolute;
        z-index: +1;
        border: none;
        background: linear-gradient(90deg, rgba(179,82,38,1) 0%, rgba(208,123,81,1) 50%, rgba(240,167,128,1) 100%);
        font-family: Quicksand-VariableFont;
        color: white;
        letter-spacing: 1px;
        font-size: 3.889vw;
      }
    }
  }
}










@media screen and (max-width: 450px) {

  /* small screen part2 container container container */
  .ss-part2-container-container-container {

    /* small screen part2 container decoration */
    .ss-part2-container-decoration {
      width: 60px;
      height: 60px;
    }
  }
}

@media screen and (max-width: 436px) {

  /* small screen part2 container container container */
  .ss-part2-container-container-container {

    /* small screen part2 container decoration */
    .ss-part2-container-decoration {
      width: 58.1px;
      height: 58.1px;
    }
  }
}

@media screen and (max-width: 414px) {

  /* small screen part2 container container container */
  .ss-part2-container-container-container {

    /* small screen part2 container decoration */
    .ss-part2-container-decoration {
      width: 55.2px;
      height: 55.2px;
    }
  }
}

@media screen and (max-width: 393px) {

  /* small screen part2 container container container */
  .ss-part2-container-container-container {

    /* small screen part2 container decoration */
    .ss-part2-container-decoration {
      width: 52.4px;
      height: 52.4px;
    }
  }
}

@media screen and (max-width: 375px) {

  /* small screen part2 container container container */
  .ss-part2-container-container-container {

    /* small screen part2 container decoration */
    .ss-part2-container-decoration {
      width: 50px;
      height: 50px;
    }
  }
}

@media screen and (max-width: 360px) {

  /* small screen part2 container container container */
  .ss-part2-container-container-container {

    /* small screen part2 container decoration */
    .ss-part2-container-decoration {
      width: 48px;
      height: 48px;
    }
  }
}

@media screen and (min-width: 451px) {
  .ss-part2-container {
    display: none;
  }
}