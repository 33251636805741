* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen employe navigation one icon container */
.ss-employe-navigation-one-icon-container {
  width: 17.8vw;
  height: 100%;
  border-left: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-employe-navigation-one-icon-container img {
  width: 50.746%;
}