* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen employe services list box container */
.ss-employe-services-list-box-container {
  width: 100%;
  height: 5.911vh;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.ss-employe-services-list-box-container p {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 3.200vw;
  font-weight: 400;
  line-height: 1.1;
  padding-left: 4.267vw;
  padding-right: 4.267vw;
}