* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen marija andric container */
.ss-marija-andric-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen marija andric container container */
.ss-marija-andric-container-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 451px) {
  /* small screen marija andric container */
  .ss-marija-andric-container {
    display: none;
  }
}










/* large screen marija andric container */
.ls-marija-andric-container {

}

@media screen and (max-width: 450px) {
  /* large screen marija andric container */
  .ls-marija-andric-container {
    display: none;
  } 
}

