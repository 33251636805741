/* large screen home part 3 container */
.ls-home-part-3-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, rgba(224,224,224,1) 0%, rgba(235,235,235,1) 5%, rgba(252,252,252,1) 10%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 85%, rgba(252,252,252,1) 90%, rgba(235,235,235,1) 95%, rgba(224,224,224,1) 100%);
}

/* large screen home part 3 services button */
.ls-home-part-3-services-button {
  width: 40vw;
  height: 8.333vw;
  background-image: linear-gradient(60deg, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%);
  margin-top: 5vw;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-home-part-3-services-button p {
  font-family: Quicksand-VariableFont;
  font-size: 3.438vw;
  font-weight: 600;
  color: white;
}

/* large screen home part 3 text container */
.ls-home-part-3-text-container {
  margin-top: 5vw;
  display: flex;
  flex-direction: row;
  gap: 6.667vw;
  margin-bottom: 5vw;
}

/* large screen home part 3 text container container */
.ls-home-part-3-text-container-container {
  width: 25.555vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.333vw;
}

@media screen and (max-width: 450px) {
  /* large screen home part 3 container */
  .ls-home-part-3-container {
    display: none;
  }
}