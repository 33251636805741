/* large screen employe profile slideshow background container */
.ls-employe-profile-slideshow-background-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
}

/* large screen employe profile slideshow container */
.ls-employe-profile-slideshow-container {
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
}

/* large screen emplye profile slideshow container hidden image - determines height of slideshow */
.ls-employe-profile-slideshow-container-hiddne-image {
  border: 3px solid black;
  border-radius: 16px;
  visibility: hidden;
}

/* large screen employe profile slideshow arrow container */
.ls-employe-profile-slideshow-arrow-container {
  width: 3.571%;
  height: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ls-employe-profile-slideshow-arrow-container img {
 width: 100%;
 cursor: pointer; 
}

.ls-employe-profile-slideshow-left-arrow {
  left: 0px;
  cursor: pointer;
}

.ls-employe-profile-slideshow-right-arrow {
  right: 0px;
  cursor: pointer;
}

.ls-employe-profile-slideshow-x-icon-container {
  width: 5.714%;
  position: absolute;
  cursor: pointer;
  right: 0px;
}

@media screen and (max-width: 450px) {
  .ls-employe-profile-slideshow-background-container {
    display: none;
  }
}

@media screen and (max-width: 1439px) {
  .ls-employe-profile-slideshow-container-hiddne-image {
    max-width: 600px;
    max-height: 600px;
  }
}

@media screen and (min-width: 1440px) {
  .ls-employe-profile-slideshow-container-hiddne-image {
    max-width: 800px;
    max-height: 800px;
  }
}