/* small screen home part 3 container */
.ss-home-part-3-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6.579vh;
}

/* small screen home part 3 button container */
.ss-home-part-3-button-container {
  width: 71.2vw;
  height: 17.777vw;
  background: linear-gradient(90deg, #A15139 0%, #B35226 52.08%, #F0A780 100%);
  margin-top: 6.579vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-home-part-3-button-container p {
  font-family: Quicksand-VariableFont;
  color: white;
  font-size: 6.4vw;
}

@media screen and (min-width: 451px) {

  /* small screen home part 3 container */
  .ss-home-part-3-container {
    display: none;
  }
}