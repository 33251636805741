* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* large screen post doctor card container */
.ls-post-doctor-card-container {
  width: 16.667vw;
  height: 21.667vw;
  background-color: #FFEEDE;
  background-repeat: no-repeat;
  background-position: top 0 right 1.667vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large screen post doctor card image container */
.ls-post-doctor-card-image-container {
  width: 13.333vw;
  height: 11.667vw;
  background-color: white;
  margin-top: 1.667vw;
}

.ls-post-doctor-card-image-container img {
  width: 100%;
}

/* large screen post doctor card text container */
.ls-post-doctor-card-text-container {
  width: 13.333vw;
  height: 8.333vw;
  background-color: white;
  margin-bottom: 1.667vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.833vw;
}

/* large screen post doctor card name */
.ls-post-doctor-card-name {
  font-family: Quicksand-VariableFont;
  font-weight: 600;
  color: rgba(163,71,39,1);
  line-height: 1;
  font-size: 1.042vw;
}

/* large screen post doctor card description */
.ls-post-doctor-card-description {
  font-family: Quicksand-VariableFont;
  color: black;
  line-height: 1.2;
  font-size: 0.833vw;
}

@media screen and (max-width: 450px) {
  /* large screen post doctor card container */
  .ls-post-doctor-card-container {
    display: none;
  }
}










/* small screen post doctor card container */
.ss-post-doctor-card-container {
  width: 100%;
  height: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* small screen post doctor card image container */
.ss-post-doctor-card-image-container {
  width: 44.444vw;
  height: 35.555vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ss-post-doctor-card-image-container img {
  max-width: 100%;
  max-height: 100%;
}

/* large screen post doctor card text container */
.ls-post-doctor-card-text-container {
  width: 44.444vw;
  height: 35.555vw;
  display: flex;
  flex-direction: column;
  gap: 3.333vw;
}

/* large screen post doctor card name */
.ss-post-doctor-card-name {
  font-family: Quicksand-VariableFont;
  font-weight: 600;
  color: rgba(163,71,39,1);
  line-height: 1;
  font-size: 3.889vw;
}

/* small screen post doctor card description */
.ss-post-doctor-card-description {
  font-family: Quicksand-VariableFont;
  color: black;
  line-height: 1.2;
  font-size: 3.333vw;
}

@media screen and (min-width: 451px) {
  /* small screen post doctor card container */
  .ss-post-doctor-card-container {
    display: none;
  }
}