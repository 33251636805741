* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen ana malivukovic container */
.ss-ana-malivukovic-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen ana malivukovic container container */
.ss-ana-malivukovic-container-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 451px) {
  /* small screen ana malivukovic container */
  .ss-ana-malivukovic-container {
    display: none;
  }
}










/* large screen ana malivukovic container */
.ls-ana-malivukovic-container {

}

@media screen and (max-width: 450px) {
  /* large screen ana malivukovic container */
  .ls-ana-malivukovic-container {
    display: none;
  } 
}

