* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen doctors newsletter container */
.ss-doctors-newsletter-container {
  width: 100vw;
  height: 27.586vh;
  background: linear-gradient(90deg, #A15139 0%, #B35226 48.29%, #F0A780 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8.889vw;
}

/* small screen doctors newsletter container container */
.ss-doctors-newsletter-container-container {
  width: 100vw;
  height: 19.212vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* small screen doctors newsletter logo */
.ss-doctors-newsletter-logo {
  height: 3.695vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-doctors-newsletter-logo img {
  height: 100%;
}

/* small screen doctors newsletter paragraph */
.ss-doctors-newsletter-paragraph {
  width: 71.733vw;
}

.ss-doctors-newsletter-paragraph p {
  font-family: Quicksand-VariableFont;
  font-size: 3.733vw;
  color: white;
  font-weight: 500;
}

/* small screen doctors newsletter input & button */
.ss-doctors-newsletter-input-button {
  height: 3.941vh;
  width: 71.733vw;
  overflow-y: hidden;
}

.ss-doctors-newsletter-input-button form {
  height: 100%;
}

/* small screen doctors newsletter input */
.ss-doctors-newsletter-input {
  height: 100%;
  width: 49.867vw;
  border: none;
  font-family: Quicksand-VariableFont;
  text-indent: 2.667vw;
  font-weight: 400;
  color: black;
  font-size: 3.200vw;
}

.ss-doctors-newsletter-input::placeholder {
  color: #BCBCBC;
}

.ss-doctors-newsletter-input:focus {
  outline: none;
}

/* small screen doctors newsletter button */
.ss-doctors-newsletter-button {
  width: 21.866vw;
  height: 100%;
  border: none;
  background: black;
  color: white;
  font-family: Quicksand-VariableFont;
  font-weight: 600;
  font-size: 3.733vw;
}