* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* large screen blog right small post container */
.ls-blog-right-small-post-container {
  width: 25vw;
  height: 13.333vw;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.667vw;
}

/* large screen blog right small post image container */
.ls-blog-right-small-post-image-container {
  width: 10vw;
  height: 10vw;
}

.ls-blog-right-small-post-image-container img {
  width: 10vw;
}

/* large screen blog right small post text container */
.ls-blog-right-small-post-text-container {
  width: 10vw;
  height: 10vw;
  overflow: hidden;
}

/* large screen blog right small post title */
.ls-blog-right-small-post-title {
  font-family: Quicksand-VariableFont;
  font-weight: 600;
  font-size: 1.041vw;
  color: #A34727;
  line-height: 1.2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* large screen blog right small post paragraph */
.ls-blog-right-small-post-paragraph {
  font-family: Quicksand-VariableFont;
  font-size: 0.9375vw;
  color: black;
  margin-top: 0.833vw;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 450px) {
  .ls-blog-right-small-post-container {
    display: none;
  } 
}