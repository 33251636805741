/* large screen home newsletter container */
.ls-home-newsletter-container {
  width: 100%;
  background-image: linear-gradient(60deg, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large screen home newsletter container container */
.ls-home-newsletter-container-container {
  width: 53.333vw;
  display: flex;
  flex-direction: column;
  gap: 3.333vw;
  margin-top: 3.333vw;
  margin-bottom: 5vw;
}

/* large screen home newsletter logo container */
.ls-home-newsletter-logo-container {
  width: 53.333vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ls-home-newsletter-logo-container img {
  height: 100%;
  cursor: pointer;
}

/* large screen home newsletter text & input & button container */
.ls-home-newsletter-text-input-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

/* large screen home newsletter text */
.ls-home-newsletter-text {
  font-family: Quicksand-VariableFont;
  font-size: 1.667vw;
  color: white;
}

/* large screen home newsletter input & button container */
.ls-home-newsletter-input-button-container {
  width: 100%;
  height: 3.333vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* large screen home newsletter input */
.ls-home-newsletter-input {
  width: 38.333vw;
  height: 100%;
  border: none;
  outline: none;
  font-family: Quicksand-VariableFont;
  font-size: 1.146vw;
  padding-left: 3.333vw;
  color: black;
}

.ls-home-newsletter-input::placeholder {
  color: black;
}

/* large screen home newsletter button */
.ls-home-newsletter-button {
  width: 13.333vw;
  height: 100%;
  background: linear-gradient(90deg, rgba(250,200,100,1) 0%, rgba(250,200,100,1) 100%);
  border: none;
  cursor: pointer;
  font-size: 1.146vw;
  color: white;
  letter-spacing: 0.260vw;
}

@media screen and (max-width: 450px) {
  /* large screen home newsletter container */
  .ls-home-newsletter-container {
    display: flex;
  }
}