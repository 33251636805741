/* large screen home footer container */
.ls-home-footer-container {
  width: 100%;
  height: 33.333vw;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}

/* large screen home footer container container */
.ls-home-footer-container-container {
  width: 83.333vw;
  height: 16.667vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* large screen home footer text container */
.ls-home-footer-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2.5vw;
}

.ls-home-footer-text-container p {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.042vw;
}

.ls-home-footer-text-container p a {
  text-decoration: none;
  color: black;
}

/* large screen home footer logo & icons container */
.ls-home-footer-logo-icons-container {
  height: 16.667vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* large screen home footer logo 1 container */
.ls-home-footer-logo-1-container {
  height: 5.833vw;
}

.ls-home-footer-logo-1-container img {
  height: 100%;
  cursor: pointer;
}

/* large screen home footer logo 2 container */
.ls-home-footer-logo-2-container {
  height: 2.5vw;
}

.ls-home-footer-logo-2-container img {
  height: 100%;
}

/* large screen home footer icons container */
.ls-home-footer-icons-container {
  height: 2.5vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2.5vw;
}

/* large screen home footer copyright container */
.ls-home-footer-copyright-container {
  width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ls-home-footer-copyright-container  {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 0.833vw;
}

.ls-home-footer-copyright-container p a {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 450px) {
  /* large screen home footer */
  .ls-home-footer-container {
    display: none;
  } 
}