* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen doctors navigation dropdown list container */
.ss-doctors-navigatio-dropdown-list-container {
  width: 71.467vw;
  height: 59.113vh;
  margin-top: 11.453vh;
  border-bottom: 3px solid;
  border-image: linear-gradient(90deg, rgba(148,59,39,1), rgba(240,167,128,1)) 1;
}

/* small screen doctors navigation dropdown icons */
.ss-doctors-navigation-dropdown-icons-container {
  width: 71.467vw;
  position: absolute;
  bottom: 6.527vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8.8vw;
}