/* small screen home part 1 container */
.ss-home-part-1-container {
  width: 100%;
  height: 92.118vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* small screen home part 1 container container */
.ss-home-part-1-container-container {
  width: 84vw;
  height: 82.118vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.ss-home-part-1-container-container a {
  text-decoration: none;
}

/* small screen home part 1 text */
.ss-home-part-1-text {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 6.667vw;
  font-weight: 500;
  line-height: 1.2;
}

/* small screen home part 1 logo container */
.ss-home-part-1-logo-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ss-home-part-1-logo-container img {
  height: 100%;
}

/* small screen home part 1 button */
.ss-home-part-1-button {
  width: 44.444vw;
  height: 8.888vw;
  background: linear-gradient(90deg, rgba(250,200,100,1) 0%, rgba(250,200,100,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-home-part-1-button p {
  font-family: Quicksand-VariableFont;
  color: white;
  letter-spacing: 1px;
}

/* small screen home part 1 image container */
.ss-home-part-1-image-container {
  width: 84vw;
  border-bottom: 1.389vw solid;
  border-image: linear-gradient(90deg, rgba(148,59,39,1), rgba(240,167,128,1)) 1;
  position: relative;
}

.ss-home-part-1-image-container img {
  width: 100%;
}



@media screen and (min-width: 451px) {
  /* small screen home part 1 container */
  .ss-home-part-1-container {
    display: none;
  }
}

@media screen and (max-height: 779px) {
  /* small screen home part 1 logo container */
  .ss-home-part-1-logo-container {
    height: 5vh;
  }

  .ss-home-part-1-logo-container img {
    height: 100%;
  }

  /* small screen home part 1 button */
  .ss-home-part-1-button {
    width: 44.444vw;
    height: 6vh;
  }

  /* small screen home part 1 image container */
  .ss-home-part-1-image-container {
    height: 80vw;
    overflow: hidden;
  }

  .ss-home-part-1-image-container img {
    width: 100%;
  }
}

@media screen and (min-height: 780px) {
  /* small screen home part 1 logo container */
  .ss-home-part-1-logo-container {
    width: 53.333vw;
  }

  .ss-home-part-1-logo-container img {
    width: 100%;
  }

  /* small screen home part 1 button */
  .ss-home-part-1-button {
    width: 53.333vw;
    height: 17.778vw;
  }

  /* small screen home part 1 image container */
  .ss-home-part-1-image-container {
    width: 84vw;
  }

  .ss-home-part-1-image-container img {
    height: 100%;
  }
}