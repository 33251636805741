* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen employe biography container */
.ss-employe-biography-container {
  width: 71.2vw;
}

/* small screen employe biography title container */
.ss-employe-biography-title-container {
  width: 100%;
  height: 5.911vh;
  border-image: linear-gradient(90deg, rgba(148,59,39,1), rgba(240,167,128,1)) 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.ss-employe-biography-title-container p {
  font-family: Quicksand-VariableFont;
  font-size: 5.333vw;
  font-weight: 600;
  color: #A15139;
}

/* small screen employe biography paragraph */
.ss-employe-biography-subtitle {
  font-family: Quicksand-VariableFont;
  color: #A15139;
  font-size: 4.444vw;
  line-height: 1.1;
  margin-top: 3.941vh;
  margin-bottom: 2.222vh;
  font-weight: 900;
}

.ss-employe-biography-paragraph {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 3.733vw;
  line-height: 1.1;
  margin-top: 3.941vh;
  white-space: pre-wrap;
}

.ss-employe-biography-container ul {
  margin-left: 3.333vw;
}

.ss-employe-biography-container ul li {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 3.733vw;
  line-height: 1.1;
}

@media screen and (min-height: 641px) {
  /* small screen employe biography title container */
  .ss-employe-biography-title-container {
    border-top: 3px solid;
    border-bottom: 3px solid;
  }

  .ss-employe-biography-title-container p {
    font-size: 5.333vw;
  }
}

@media screen and (max-height: 640px) {
  /* small screen employe biography title container */
  .ss-employe-biography-title-container {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }

  .ss-employe-biography-title-container p {
    font-size: 4.444vw;
  }
}