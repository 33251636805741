* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen employe services list container */
.ss-employe-services-list-container {
  width: 71.2vw;
  display: flex;
  flex-direction: column;
  gap: 1.97vh;
  justify-content: center;
  align-items: center;
}