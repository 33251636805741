/* large screen home part 1 container */
.ls-home-part-1-container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large screen home part 1 container container */
.ls-home-part-1-container-container {
  width: 100%;
  position: absolute;
  top: 8.333vw;
  bottom: 0.521vw;
  border-bottom: 0.520vw solid;
  background: linear-gradient(180deg, rgba(224,224,224,1) 0%, rgba(235,235,235,1) 5%, rgba(252,252,252,1) 10%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
  border-image: linear-gradient(to right, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%) 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* large screen home part 1 text & logo & button container */
.ls-home-part-1-text-logo-button-container {
  margin-left: 6.667vw;
  display: flex;
  flex-direction: column;
  gap: 6.667vw;
}

/* large screen home part 1 text & logo container */
.ls-home-part-1-text-logo-container {
  display: flex;
  flex-direction: column;
  gap: 2.5vw;
}

/* large screen home part 1 text  */
.ls-home-part-1-text {
  font-family: Quicksand-VariableFont;
  font-size: 2.500vw;
  color: black;
  font-weight: 500;
}

/* large screen home part 1 logo  */
.ls-home-part-1-logo {
  width: 25vw;
}

/* large screen home part 1 button  */
.ls-home-part-1-button {
  width: 25vw;
  height: 6.667vw;
  background: linear-gradient(90deg, rgba(250,200,100,1) 0%, rgba(250,200,100,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-home-part-1-button p {
  font-size: 0.938vw;
  color: white;
  letter-spacing: 0.260vw;
}

/* large screen home part 1 image container */
.ls-home-part-1-image-container {
  position: absolute;
  top: 3.333vw;
  bottom: 0;
  right: 6.667vw;
  display: flex;
}

.ls-home-part-1-image-container img {
  max-height: 100%;
  z-index: +1;
}




/* large screen home part 1 after margin container */
.ls-home-part-1-margin-container {
  width: 100%;
  height: 6.667vw;
  background: linear-gradient(0deg, rgba(190,190,190,1) 0%, rgba(221,221,221,1) 25%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
}

@media screen and (max-width: 450px) {
  /* large screen home part 1 container */
  .ls-home-part-1-container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 1279px) {
  /* large screen home part 1 container */
  .ls-home-part-1-container {
    height: 46.667vw;
  }
}


