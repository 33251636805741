* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen employe navigation icons container */
.ss-employe-navigation-icons-container {
  width: 71.2vw;
  height: 7.882vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
}