/* small screen part 5 container newsletter */
.ss-part5-container-newsletter {
  width: 100%;
  height: 30.909vh;
  background: linear-gradient(90deg, rgba(148,59,39,1) 0%, rgba(240,167,128,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* small screen part 5 newsletter container container */
.ss-part5-newsletter-container-container {
  width: 82.222%;
  height: 23.03vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* small screen part 5 newsletter container logo */
.ss-part5-newsletter-container-logo {
  width: 100%;
  height: 2.424vh;
  display: flex;
  flex-direction: column;
}

.ss-part5-newsletter-container-logo img {
  height: 100%;
}

/* small screen part 5 newsletter container paragraph */
.ss-part5-newsletter-container-paragraph {
  font-family: Quicksand-VariableFont;
  font-size: 3.889vw;
  color: white;
}

/* small screen part 5 newsletter container input */
.ss-part5-newsletter-container-input {
  width: 100%;
  height: 4.848vh;
  border: none;
  background-color: white;
  font-family: Quicksand-VariableFont;
  font-size: 2.778vw;
  text-indent: 4.444%
}

.ss-part5-newsletter-container-input::placeholder {
  font-family: Quicksand-VariableFont;
  font-size: 2.778vw;
  color: black;
  text-indent: 4.444%;
}

.ss-part5-newsletter-container-input:focus {
  outline: none;
}

/* small screen part 5 newsletter container button */
.ss-part5-newsletter-container-button {
  width: 100%;
  height: 4.242vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ss-part5-newsletter-container-button button {
  width: 28.378%;
  height: 100%;
  border: none;
  background-color: black;
  color: white;
  font-family: Quicksand-VariableFont;
  font-size: 2.778vw;
  letter-spacing: 1px;
}

@media screen and (min-width: 451px) {
  /* small screen part 5 container newsletter */
  .ss-part5-container-newsletter {
    display: none;
  }
}