/* large screen home part 4 container */
.ls-home-part-4-cotainer {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* large screen home part 4 team button */
.ls-home-part-4-team-button {
  width: 40vw;
  height: 8.333vw;
  background-image: linear-gradient(60deg, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%);
  margin-top: 5vw;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-home-part-4-team-button p {
  font-family: Quicksand-VariableFont;
  font-size: 3.438vw;
  font-weight: 600;
  color: white;
}

/* large screen home part 4 team container */
.ls-home-part-4-team-container {
  margin-top: 8.333vw;
  margin-bottom: 8.333vw;
  display: flex;
  flex-direction: column;
  gap: 5vw;
}

/* large screen home part 4 team row container */
.ls-home-part-4-team-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5vw;
}

@media screen and (max-width: 450px) {
  /* large screen home part 4 container */
  .ls-home-part-4-cotainer {
    display: none;
  }
}