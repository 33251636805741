* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen jelena stojkovic-filipovic container */
.ss-jelena-stojkovicfilipovic-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen jelena stojkovic-filipovic container container */
.ss-jelena-stojkovicfilipovic-container-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 451px) {
  /* small screen jelena stojkovic-filipovic container */
  .ss-jelena-stojkovicfilipovic-container {
    display: none;
  }
}










/* large screen jelena stojkovic-filipovic container */
.ls-jelena-stojkovicfilipovic-container {

}

@media screen and (max-width: 450px) {
  /* large screen jelena stojkovic-filipovic container */
  .ls-jelena-stojkovicfilipovic-container {
    display: none;
  } 
}

