* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen queastion part 5 container */
.ss-question-part5-container {
  width: 100%;

  /* small screen queastion part 5 title */
  .ss-question-part5-title {
    width: 100%;
    height: 6.666vh;
    border-top: 3px solid;
    border-image: linear-gradient(90deg, rgba(148,59,39,1), rgba(240,167,128,1)) 1;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 6.667%;
  }

  /* small screen queastion part 5 text */
  .ss-question-part5-title p {
    font-family: Quicksand-VariableFont;
    font-size: 3.889vw;
    font-weight: 500;
  }

  /* small screen queastion part 5 icon */
  .ss-question-part5-icon {
    height: 2.222vh;
    display: flex;
  }

  .ss-question-part5-icon img {
    height: 100%;
  }

  /* small screen queastion part 5 paragraph */
  .ss-question-part5-paragraph {
    font-family: Quicksand-VariableFont;
    font-size: 3.889vw;
    padding: 2.222vh;
  }
}