* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Quicksand-VariableFont;
  src: url(../../assets/fonts/Quicksand-VariableFont/Quicksand-VariableFont.ttf);
}

/* small screen services container */
.ss-services-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen services container container */
.ss-services-container-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* small screen services button container */
.ss-services-button-container {
  width: 71.2vw;
  height: 17.777vw;
  background: linear-gradient(90deg, #A15139 0%, #B35226 52.08%, #F0A780 100%);
  margin-top: 8.889vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-services-button-container p {
  font-family: Quicksand-VariableFont;
  color: white;
  font-size: 6.4vw;
  font-weight: 500;
}

@media screen and (min-width: 451px) {
  /* small screen services container */
  .ss-services-container {
    display: none;
  }
}










/* large screen services container */
.ls-services-container {
}

/* large screen services background 1 */
.ls-services-background-1 {
  width: 100%;
  height: 6.667vw;
  background: linear-gradient(180deg, rgba(228,228,228,1) 0%, rgba(240,240,240,1) 25%, rgba(253,253,253,1) 50%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
}

/* large screen services background 2 */
.ls-services-background-2 {
  width: 100%;
  height: 5vw;
  background: linear-gradient(180deg, rgba(228,228,228,1) 0%, rgba(240,240,240,1) 25%, rgba(253,253,253,1) 50%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
}

@media screen and (max-width: 450px) {
  /* large screen services container */
  .ls-services-container {
    display: none;
  } 
}