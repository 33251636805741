/* large screen home part 2 container */
.ls-home-part-2-container {
  width: 100%;
  height: 47.667vw;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* large screen home part 2 container container */
.ls-home-part-2-container-container {
  width: fit-content;
  height: 39.334vw;
  display: flex;
  flex-direction: row;
}

/* large screen home part 2 text container */
.ls-home-part-2-text-container {
  width: fit-content;
  height: 39.334vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ls-home-part-2-text-container p {
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 3.438vw;
  font-weight: 600;
  line-height: 1;
  
}

/* large screen home part 2 decoration & form container */
.ls-home-part-2-decoration-form-container {
  width: 63.333vw;
  height: 39.334vw;
  position: relative;
}


/* large screen home part 2 form container */
.ls-home-part-2-form-container {
  width: 57.916vw;
  height: 39.334vw;
  background-color: rgba(255, 255, 255, 0.4);;
  z-index: +1;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.333vw;
}

/* large screen home part 2 form name & email container */
.ls-home-part-2-form-name-email-container {
  width: 54.583vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* large screen home part 2 form surname & phonenumber container */
.ls-home-part-2-form-surname-phonenumber-container {
  width: 54.583vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* large screen home part 2 form input container */
.ls-home-part-2-form-input-container {
  width: 26.458vw;
  height: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ls-home-part-2-form-input-container p {
  font-family: Quicksand-VariableFont;
  font-size: 1.146vw;
  color: black;
}

.ls-home-part-2-form-input-container input {
  width: 100%;
  height: 2.5vw;
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 0.260vw solid #755f52;
  font-family: Quicksand-VariableFont;
  font-size: 1.146vw;
  outline: none;
  color: black;
}

.ls-home-part-2-form-input-container input::placeholder {
  color: #979290;
}

.ls-home-part-2-form-input-container input::-webkit-outer-spin-button,
.ls-home-part-2-form-input-container input::-webkit-inner-spin-button {
  -webkit-appearance: none; /* hide arrows in input */
}

/* large screen home part 2 form message container */
.ls-home-part-2-form-message-container {
  width: 54.583vw;
  height: 8.333vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ls-home-part-2-form-message-container p {
  font-family: Quicksand-VariableFont;
  font-size: 1.146vw;
  color: black;
}

.ls-home-part-2-form-message-textarea-div {
  width: 100%;
  height: 5vw;
  font-family: Quicksand-VariableFont;
  font-size: 1.146vw;
  color: black;
  outline: none;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: hidden;
  border-bottom: 0.260vw solid #755f52;
}

.ls-home-part-2-form-message-textarea-div[placeholder]:empty:before {
  content: attr(placeholder);
  font-family: Quicksand-VariableFont;
  font-size: 1.146vw;
  color: #979290;
}

.ls-home-part-2-form-message-container textarea {
  display: none;
}

/* large screen home part 2 form logo & button container */
.ls-home-part-2-form-logo-button-container {
  width: 54.583vw;
  height: 4.167vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* large screen home part 2 form logo */
.ls-home-part-2-form-logo {
  width: 23.333vw;
  cursor: pointer;
}

/* large screen home part 2 form button */
.ls-home-part-2-form-button {
  width: 20vw;
  height: 4.167vw;
  background: linear-gradient(90deg, rgba(250,200,100,1) 0%, rgba(250,200,100,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.ls-home-part-2-form-button p {
  font-size: 0.938vw;
  color: white;
  letter-spacing: 0.260vw;
}

@media screen and (max-width: 450px) {
  /* large screen home part 2 container */
  .ls-home-part-2-container {
    display: none;
  }
}