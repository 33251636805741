* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen doctors header container */
.ss-doctors-header-container {
  width: 100vw;
  height: 7.882vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
}

/* small screen doctors header container container */
.ss-doctors-header-container-container {
  width: 84vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* small screen doctors header container logo */
.ss-doctors-header-container-logo {
  height: 2.34vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-doctors-header-container-logo img {
  width: auto;
  height: 100%;
}

/* small screen doctors header container navigation icon */
.ss-doctors-header-container-navigation-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ss-doctors-header-container-navigation-icon img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 450px) {
  /* small screen doctors header container navigation icon */
  .ss-doctors-header-container-navigation-icon {
    width: 28.8px;
    height: 24px;
  }
}

@media screen and (max-width: 430px) {
  /* small screen doctors header container navigation icon */
  .ss-doctors-header-container-navigation-icon {
    width: 27.5px;
    height: 22.9px;
  }
}

@media screen and (max-width: 414px) {
  /* small screen doctors header container navigation icon */
  .ss-doctors-header-container-navigation-icon {
    width: 26.5px;
    height: 22.1px;
  }
}

@media screen and (max-width: 393px) {
  /* small screen doctors header container navigation icon */
  .ss-doctors-header-container-navigation-icon {
    width: 25.2px;
    height: 21px;
  }
}

@media screen and (max-width: 375px) {
  /* small screen doctors header container navigation icon */
  .ss-doctors-header-container-navigation-icon {
    width: 24px;
    height: 20px;
  }
}

@media screen and (max-width: 360px) {
  /* small screen doctors header container navigation icon */
  .ss-doctors-header-container-navigation-icon {
    width: 24px;
    height: 20px;
  }
}

@media screen and (min-width: 451px) {
  /* small screen doctors header container */
  .ss-doctors-header-container {
    display: none;
  }
}