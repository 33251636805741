/* large screen home part 3 field container */
.ls-home-part-3-field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
}

/* large screen home part 3 title container */
.ls-home-part-3-title-container {
  width: 100%;
  height: 4.167vw;
  border-top: 0.156vw solid;
  border-bottom: 0.156vw solid;
  border-image: linear-gradient(to right, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%) 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ls-home-part-3-title-container p {
  font-family: Quicksand-VariableFont;
  color: rgba(152,65,38,1);
  font-size: 1.146vw;
}

/* large screen home part 3 image */
.ls-home-part-3-image {width: 13.333vw;
  width: 13.333vw;
}

/* large screen home part 3 content container */
.ls-home-part-3-content-container {
  
}

.ls-home-part-3-content-container li {
  list-style: none;
  font-family: Quicksand-VariableFont;
  color: black;
  font-size: 1.146vw;
}

.ls-home-part-3-content-bold {
  font-weight: 700;
}

.ls-home-part-3-content-horizontal-rule {
  border-top: 0.156vw solid;
  margin-top: 0.833vw;
  margin-bottom: 0.833vw;
  border-image: linear-gradient(to right, rgba(152,65,38,1) 0%, rgba(240,166,131,1) 100%) 1;
}

@media screen and (max-width: 450px) {
  /* large screen home part 3 field container */
  .ls-home-part-3-field-container {
    width: 100%;
  }
}

