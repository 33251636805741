* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* small screen zeljko stanojlovic container */
.ss-zeljko-stanojlovic-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* small screen zeljko stanojlovic container container */
.ss-zeljko-stanojlovic-container-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 451px) {
  /* small screen zeljko stanojlovic container */
  .ss-zeljko-stanojlovic-container {
    display: none;
  }
}










/* large screen zeljko stanojlovic container */
.ls-zeljko-stanojlovic-container {

}

@media screen and (max-width: 450px) {
  /* large screen zeljko stanojlovic container */
  .ls-zeljko-stanojlovic-container {
    display: none;
  } 
}

