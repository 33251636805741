/* small screen employe profile container */
.ss-employe-profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 451px) {
  /* small screen employe profile container */
  .ss-employe-profile-container {
    display: none;
  }
}

/* small screen post video container */
.ss-post-video-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8.889vw;
} 

.ss-post-video-title {
  width: 80vw;
  font-family: AvenirNextCyr-Demi;
  color: black;
  font-size: 4.444vw;
  padding-top: 8.889vw;
}

.ss-post-video-title span {
  color: rgba(102,151,54,1);
}